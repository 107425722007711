(function() {
    'use strict';

    angular.module('print')
        .factory('PrintService', PrintService);

    PrintService.$inject = [ '$http', '$window']

    function PrintService($http, $window) {
        return {
            printClassification: printClassification,
            printCategory: printCategory,
            printItemType: printItemType,
        };


        function printClassification(accountID, classificationID, unchecked, locationID) {
            var s = 'app/private/phpscripts/print.php?objClass=Classification&id=' + classificationID + '&acc=' + accountID ;
            if (unchecked) {
                s = s + '&unchecked=true';
            }
            if (locationID) {
                s = s + '&loc=' + locationID;
            }
            $window.open(s);
        }

        function printCategory(accountID, categoryID, classificationID, unchecked, locationID) {
            var s = 'app/private/phpscripts/print.php?objClass=Category&id=' + categoryID + '&acc=' + accountID + '&cls=' + classificationID;
            if (unchecked) {
                s = s + '&unchecked=true';
            }
            if (locationID) {
                s = s + '&loc=' + locationID;
            }
            $window.open(s);
        }

        function printItemType(accountID, itemTypeID, classificationID, unchecked, locationID) {
            var s = 'app/private/phpscripts/print.php?objClass=ItemType&id=' + itemTypeID + '&acc=' + accountID + '&cls=' + classificationID;
            if (unchecked) {
                s = s + '&unchecked=true';
            }
            if (locationID) {
                s = s + '&loc=' + locationID;
            }
            $window.open(s);
        }

    }

})();
