(function() {
    'use strict';

    angular.module('itemType')
        .controller('ItemTypeController', ItemTypeController);

    ItemTypeController.$inject = ['$rootScope', '$state', '$scope', 'DataService',  '$timeout'];

    function ItemTypeController($rootScope, $state, $scope, DataService, $timeout) {

        var vm = this;

        vm.sortPropertyName = 'item_field_1';
        vm.reverse = false;
        vm.isLoading = false;
        vm.unshownItems = [];

        vm.showMore = showMore;

        vm.setActiveItem = setActiveItem;
        vm.setSortField = setSortField;


        $scope.$on('repairRequested', function(event, args) { // event & args have to remain on all of these
            loadRepairs();
        });

        $scope.$on('itemTypeChanged', function(event, args) {
            loadItemType();
        });

        $scope.$on('categoryChanged', function(event, args) {
            clearItemType();
        });

        $scope.$on('classificationChanged', function(event, args) {
            clearItemType();
        });

        $scope.$on('searchRequested', function(event, args) {
            loadSearchResults();
        });

        $scope.$on('locationChanged', function(event, args) {
            vm.activeItem = null;
        });

        function showMore() {
            if (vm.unshownItems.length > 0) {
                var moreItems = vm.unshownItems.splice(0,20);
                vm.items = vm.items.concat(moreItems);
            }

        }



      // ******************************************* ACCESSIBLE FUNCTIONS  *******************************************


        // CHANGE SPECIFIC ITEM SELECTION
        function setActiveItem(item) {
            if (vm.activeItem === item) {
                vm.activeItem = null;
            } else {
                vm.activeItem = item;
            }
            $timeout(function() {
                $rootScope.$broadcast('activeItemChanged', vm.activeItem);
            }, 10);
        }


        // CHANGE FILTER FOR COLUMNS
        function setSortField(sortPropertyName) {
            vm.reverse = (vm.sortPropertyName === sortPropertyName) ? !vm.reverse : false;
            vm.sortPropertyName = sortPropertyName;
        }



        // ******************************************* PRIVATE FUNCTIONS  *******************************************

        function clearItemType() {
            vm.items = [];
            setActiveItem(null);
            vm.activeItem = null;  // this is required to show selection in list
            $scope.InventoryCtrl.repairMode = false;
        }


        function loadItemType() {
            if ($scope.InventoryCtrl.masterTree) {
                if ($scope.InventoryCtrl.activeItemType) {
                    clearItemType();
                    vm.activeItemType = $scope.InventoryCtrl.activeItemType;

                    vm.isLoading = true;
                    //
                    DataService.get('Account', $scope.InventoryCtrl.account.account_id, 'get_items', vm.activeItemType.itemType_id).then(function(result) {
                        vm.unshownItems = result;
                        vm.items = vm.unshownItems.splice(0,30);
                        vm.isLoading = false;
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                } else {
                    console.log('no itemtype set');
                }
            } else {
                $state.go('app.inventory.classification');
            }
        }

        // LOAD REPAIR ITEMS WHEN WRENCH IS CLICKED (responds to repairRequested)
        function loadRepairs() {
            clearItemType();
            $scope.InventoryCtrl.repairMode = true;
            vm.isLoading = true;
            DataService.get('Account', $scope.InventoryCtrl.account.account_id, 'get_repairs', null).then(function(result) {
                vm.isLoading = false;
                vm.items = result;
                //$rootScope.items = result; // for print menu
                vm.activeItemID = null;
                //$rootScope.activeItem = null;
            })
            .catch(function(error) {
                console.log(error);
            });
        }

        function loadSearchResults() {
            clearItemType();
            vm.isLoading = true;
            DataService.get('Account', $scope.InventoryCtrl.account.account_id, 'get_search_results', $scope.InventoryCtrl.searchText).then(function(result) {
                vm.isLoading = false;
                vm.items = result;
                vm.activeItemID = null;
            })
            .catch(function(error) {
                console.log(error);
            });
        }

    }
})();
