(function(){
'use strict';

angular.module('inventory')
    .controller( 'InventoryController', InventoryController);

    InventoryController.$inject = ['$uibModal', '$rootScope', '$scope', '$window', '$timeout', '$sessionStorage', '$state', 'DataService', 'PrintService'];

    function InventoryController($uibModal, $rootScope, $scope, $window, $timeout, $sessionStorage, $state, DataService, PrintService ) {
        var vm = this;

        vm.masterTree = null;

        vm.clearPhysicalChecks = clearPhysicalChecks;
        vm.printClassification = printClassification;
        vm.playSelection = playSelection;
        vm.printCategory = printCategory;
        vm.printItemType = printItemType;
        vm.selectMenuItem = selectMenuItem;
        vm.showRepair = showRepair;

        vm.showAddItemModal = showAddItemModal;

        vm.search = search;

        activate();


        function activate() {
            $scope.$storage = $sessionStorage;
            $scope.AccessCtrl.account = $scope.$storage.account;
            if ( $scope.AccessCtrl.account ) {
                vm.account = $scope.AccessCtrl.account;

                DataService.get('Account', vm.account.account_id, 'get_master_tree', null).then(function(result) {
                    vm.masterTree = result;
                })
                .catch(function(error) {
                    console.log(error);
                });

                vm.setLocation= function(locID) {
                    vm.activeLocation = $.grep(vm.account.locations, function(n) {
                        return n.location_id === locID;
                    })[0];
                    $timeout(function() {
                        $rootScope.$broadcast('locationChanged');  // ItemTypeController clears the activeItem
                    }, 10);
                };

                $state.go('app.inventory.classification');

            } else {
                $state.go('app.access.login');
            }
        }

        // ******************************************* ACCESSIBLE FUNCTIONS  *******************************************





        function showAddItemModal() {
            var newObject = {};
            newObject.InventoryCtrl = vm;
            newObject.AccessCtrl = $scope.AccessCtrl;

            newObject.classification = vm.activeClassification;
            newObject.category = vm.activeCategory;
            newObject.itemType = vm.activeItemType;
            newObject.account = $scope.AccessCtrl.account;
            if (newObject.account.locations.length > 0) {
                if($scope.InventoryCtrl.activeLocation) {
                    newObject.location = vm.activeLocation;
                }
            }


            var modalInstance = $uibModal.open({
                templateUrl: 'app/views/partials/add-item-modal.html',
                controller: 'ModalInstanceCtrl',
                size: 'sm',
                resolve: {
                    modalObject: function () {
                        return newObject;
                    }
                }
            });

            modalInstance.result.then(
                function (modalResult) {

                    var item = {};
                    item.item_f_itemType_id = modalResult.itemType.itemType_id;
                    item.item_f_account_id = modalResult.account.account_id;
                    item.item_field_1 = modalResult.item_field_1;
                    item.item_field_2 = modalResult.item_field_2;
                    item.item_field_3 = modalResult.item_field_3;
                    item.item_field_4 = modalResult.item_field_4;
                    item.item_field_7 = modalResult.item_field_7;
                    item.item_field_8 = modalResult.item_field_8;
                    item.item_field_9 = modalResult.item_field_9;
                    item.item_field_10 = modalResult.item_field_10;
                    if ( modalResult.location ) {
                        item.item_f_location_id = modalResult.location.location_id;
                    }
                    item.item_link = modalResult.item_link;

                    DataService.submitObj('Item', item).then( function() {
                        var actionDesc = 'added ' + modalResult.itemType.itemType_name + ' - ' + item.item_field_2 + ' - ' + item.item_field_3;
                        if (vm.account.locations.length > 1) {
                            actionDesc += ' - ' + modalResult.location.location_name;
                        }
                        var now = moment().format('YYYY-MM-DD');
                        var newAction = {
                            action_account_id: vm.account.account_id,
                            action_username: vm.account.username,
                            action_description: actionDesc,
                            action_date: now
                        };
                        DataService.submitObj('Action', newAction).then(function() {
                            if( modalResult.saveMore) {
                                showAddItemModal();
                            } else {
                                if (vm.activeItemType) {
                                    $rootScope.$broadcast('itemTypeChanged');  // to force reload
                                }
                            }
                        })
                        .catch(function(error) {
                            console.log(error);
                        });

                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                },
                function () {
                    // error
                }
            );
        }

        function clearPhysicalChecks() {
            if (confirm("Are you sure?") ) {
                DataService.action('Account', vm.account.account_id, 'clear_physical_checks').then(function() {
                    $timeout(function() {
                        $window.alert('All physical checks have been cleared.');
                        $state.go('app.inventory.classification');
                        $timeout(function() {
                            $rootScope.$broadcast('classificationChanged');  // ItemTypeController clears all the items
                        }, 10);
                    }, 10);
                })
                .catch(function(error) {
                    console.log(error);
                });
            }
        }

        function printClassification() {
            if (vm.activeLocation) {
                PrintService.printClassification(vm.account.account_id, vm.activeClassification.classification_id, vm.showPhysical, vm.activeLocation.location_id);
            }   else {
                PrintService.printClassification(vm.account.account_id, vm.activeClassification.classification_id, vm.showPhysical);
            }
        }

        function printCategory() {
            if (vm.activeLocation) {
                PrintService.printCategory(vm.account.account_id, vm.activeCategory.category_id, vm.activeClassification.classification_id, vm.showPhysical, vm.activeLocation.location_id);
            }   else {
                PrintService.printCategory(vm.account.account_id, vm.activeCategory.category_id, vm.activeClassification.classification_id, vm.showPhysical);
            }
        }

        function printItemType() {
            if (vm.activeLocation) {
                PrintService.printItemType(vm.account.account_id, vm.activeItemType.itemType_id, vm.activeClassification.classification_id, vm.showPhysical, vm.activeLocation.location_id);
            }   else {
                PrintService.printItemType(vm.account.account_id, vm.activeItemType.itemType_id, vm.activeClassification.classification_id, vm.showPhysical);
            }
        }

        function playSelection(item) {
            var address = item.item_link;
            var prefix = (address.startsWith('http')) ? '' : 'http://';
            var rec = prefix + address;
            window.open(rec);
        }

        function selectMenuItem(selection) {
            console.log(selection);
            vm.selectedMenuItem = selection;

            if (selection.classification_name) {
                if (vm.activeClassification && selection.classification_id == vm.activeClassification.classification_id) {
                    vm.activeClassification = null;
                } else {
                    vm.activeClassification = selection;
                }
                vm.activeCategory = null;
                vm.activeItemType = null;
                $state.go('app.inventory.classification');
                $timeout(function() {
                    $rootScope.$broadcast('classificationChanged');  // ItemTypeController clears all the items
                }, 10);
            }
            if (selection.category_name) {
                if (vm.activeCategory && selection.category_id == vm.activeCategory.category_id) {
                    vm.activeCategory = null;
                } else {
                    vm.activeCategory = selection;
                }

                vm.activeItemType = null;
                $state.go('app.inventory.category');
                $timeout(function() {
                    $rootScope.$broadcast('categoryChanged');     // ItemTypeController clears all the items
                }, 10);
            }
            if (selection.itemType_name) {
                vm.activeItemType = selection;
                $state.go('app.inventory.itemtype');
                $timeout(function() {
                    $rootScope.$broadcast('itemTypeChanged');     // ItemTypeController loads matching items
                }, 10);
            }
        }

        function showRepair() {
            $rootScope.closeAll();
            vm.activeCategory = null;
            vm.activeItemType = null;
            $state.go('app.inventory.repair');
            $timeout(function() {
                $rootScope.$broadcast('repairRequested');  // ItemTypeController will load repair list
            }, 10);
        }

        function search() {
            $rootScope.closeAll();
            vm.activeCategory = null;
            vm.activeItemType = null;
            $state.go('app.inventory.searchResults');
            console.log($scope);
            // $timeout(function() {
            //     $rootScope.$broadcast('searchRequested');  // ItemTypeController will load search results
            // }, 10);
        }



    }

})();
