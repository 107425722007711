(function() {
    'use strict';

    angular.module('access')
        .controller('AccessController', AccessController);

    AccessController.$inject = ['$q', '$http', '$state', '$sessionStorage', '$window', 'DataService'];

    function AccessController($q, $http, $state, $sessionStorage, $window, DataService) {

        var vm = this;

        vm.changePassword = changePassword;  // from changePassword page
        vm.logout = logout;
        vm.login = login;


        // vm.startPasswordReset = startPasswordReset;


        activate();



        // ******************************************* ACCESSIBLE FUNCTIONS  *******************************************

        // function startPasswordReset() {
        //     var dataArray = $.param({

        //     });
        //     var config = {
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
        //         }
        //     };
        //     $http.post('app/private/phpscripts/passwordResetEmail.php', dataArray, config);
        // }

        function changePassword() {
            if (vm.changePasswordForm.$valid) {
                vm.staff.staff_hashed_password = vm.newPassword;

                DataService.submitObj('Staff', vm.staff).then(function() {
                    $window.alert('Your password was changed successfully. Please login again with new password.');
                    $state.go('app.access.login');
                })
                .catch(function(error) {
                    console.log(error);
                });

            } else {
                vm.changePasswordForm.account_new_password.$dirty = true;
                vm.changePasswordForm.account_new_password_confirm.$dirty = true;
            }
        }


        function login() {
            vm.authMsg = '';

            if (vm.loginForm.$valid) {
                vm.loggingIn = true;  // start spinner

                DataService.get('Staff', null, 'get_login', [vm.userlogin, vm.password]).then(function(result) {
                    vm.loggingIn = false;

                    var response = result[0];
                    if (response) {
                        vm.staff = response;
                        $sessionStorage.staff = vm.staff;
                        if (vm.password === 'changeme') {
                            $state.go('app.access.change');
                        } else {
                            loadAccountInfo(vm.staff.staff_f_account_id).then(function(result) {
                                vm.account = result;
                                $sessionStorage.account=vm.account;
                                $state.go('app.inventory.classification');
                                recordLoginEvent();
                            })
                            .catch(function(error) {
                                console.log(error);
                            });


                        }
                    } else {
                        vm.loggingIn = false;
                        vm.authMsg = 'No matching account found.';
                    }
                })
                .catch(function(error) {
                    vm.loggingIn = false;
                    vm.authMsg = 'No matching account found.';
                    console.log(error);
                });
            } else {
                /*jshint -W106*/
                vm.loginForm.account_username.$dirty = true;
                vm.loginForm.account_password.$dirty = true;
            }
        }




        function logout() {
            delete $sessionStorage.account;
            delete $sessionStorage.staff;
            vm.account = {};
            vm.staff = {};
            $state.go('app.access.login');
        }  //end logout





        function recordLoginEvent() {
            var now = moment().format('YYYY-MM-DD');
            var newLogin = {
                login_f_staff_id: vm.staff.staff_id,
                login_f_account_id: vm.account.account_id,
                login_timestamp: now,
                login_note: vm.account.account_name + ': ' + vm.staff.staff_name
            };
            DataService.submitObj('Login', newLogin);
        }






        // ******************************************* PRIVATE FUNCTIONS  *******************************************

        function activate() {
            if ($sessionStorage.account) {
                vm.account = $sessionStorage.account;
                vm.staff = $sessionStorage.staff;
            } else {
                vm.account = {};
            }

            vm.authMsg = '';
        } // end activate

        function loadAccountInfo(accountID) { // also loads locations and shops
            return DataService.get('Account', accountID, 'get_account', null);
        }


    }

})();
