(function () {
    'use strict';

    angular
        .module('app.lazyload')
        .constant('APP_REQUIRES', {
            // jQuery based and standalone scripts
            scripts: {
                'modernizr':                ['vendor/modernizr/modernizr.custom.js'],
                'icons':                    ['vendor/fontawesome/css/font-awesome.min.css',
                                            'vendor/simple-line-icons/css/simple-line-icons.css'
                ],
                'naturalSort':              ['vendor/angularjs-naturalsort-master/dist/naturalSortVersionDates.min.js'],
                'whirl':                    ['vendor/whirl/dist/whirl.min.css'],
                'moment' :                  ['vendor/moment/min/moment-with-locales.min.js'],
                // 'inputmask':                ['vendor/jquery.inputmask/dist/jquery.inputmask.bundle.js'],
                // 'jasny':                    ['vendor/jasny-bootstrap/dist/js/jasny-bootstrap.min.js',
                //                             'vendor/jasny-bootstrap/dist/css/jasny-bootstrap.min.css']
            },
            // Angular based script (use the right module name)
            modules: [
                {name: 'localytics.directives',     files: ['vendor/chosen/chosen.jquery.js',
                                                            'vendor/chosen/chosen.css',
                                                            'vendor/angular-chosen-localytics/dist/angular-chosen.min.js'],
                                                              serie: true},
                {name: 'ui.checkbox',               files: ['vendor/angular-bootstrap-checkbox/angular-bootstrap-checkbox.js']},
                {name: 'bm.bsTour',                 files: ['vendor/bootstrap-tour/build/js/bootstrap-tour.min.js',
                                                            'vendor/angular-bootstrap-tour/dist/angular-bootstrap-tour.min.js',
                                                            'vendor/bootstrap-tour/build/css/bootstrap-tour.min.css']},
                {name: 'ui.scroll',                 files: ['vendor/angular-ui-scroll/dist/ui-scroll.min.js',
                                                            'vendor/angular-ui-scroll/dist/ui-scroll-grid.min.js']},
                {name: 'ngInfiniteScroll',          files: ['vendor/ngInfiniteScroll/build/ng-infinite-scroll.js']}
                
            ]
        });

})();
