(function() {
    'use strict';

    angular.module('item')
        .controller('ItemController', ItemController);
        
    ItemController.$inject=['$scope', '$rootScope', 'ItemService', 'DataService', '$uibModal'];
    
    function ItemController($scope, $rootScope, ItemService, DataService, $uibModal) {
        var vm = this;
        vm.item = $scope.item;
        vm.isActiveItem = false;
        vm.eventFilter = null;

        vm.confirmDelete = confirmDelete;
        vm.loadEvents = loadEvents;
        vm.performAction = performAction;
        vm.updateSpecialActionButton = updateSpecialActionButton;

        vm.showUpdateItemModal = showUpdateItemModal;
        vm.togglePhysicalCheck = togglePhysicalCheck;
        vm.showCreateEventModal = showCreateEventModal;
        vm.showUpdateEventModal = showUpdateEventModal;
        

        $scope.$on('activeItemChanged', function(event, newVal) {
            vm.isActiveItem = false;
            if (newVal) {
                if (newVal.item_id === vm.item.item_id) {
                    vm.isActiveItem = true;
                    updateSpecialActionButton();
                    loadEvents();
                }
            }
        });

        // ******************************************* ACCESSIBLE FUNCTIONS  *******************************************

        function showUpdateEventModal(eventType) {
            var eventObject = {};
            if (eventType == 1 || eventType == 5) {
                eventObject = vm.currentUserEvent;
            } else {
                if (eventType == 2) {
                    eventObject = vm.currentRepairEvent;
                }
            }


            eventObject.attrs = getAttrs();

            var modalInstance = $uibModal.open({
                templateUrl: 'app/views/partials/event-modal-update.html',
                controller: 'ModalInstanceCtrl',
                size:'small',
                resolve: {
                    modalObject: function() {
                        return eventObject;
                    }
                }
            });

            modalInstance.result.then(
                function(modalResult) {
                    modalResult.event_date_2 = moment().format('YYYY-MM-DD');
                    modalResult.event_active = 0;

                    DataService.submitObj('Event', modalResult)
                        .then(function() {
                            if (eventType==1 || eventType==7) {
                                vm.item.item_field_5 = '';
                                vm.item.item_flag_1 ='';
                                vm.item.loaner_flag = '';
                                ItemService.checkinItem(vm.item)
                                    .then(function() {
                                        vm.loadEvents();
                                        vm.updateSpecialActionButton();
                                    })
                                    .catch(function(error) {
                                        console.log(error);
                                    });
                            }
                            if (eventType==5) {
                                vm.item.item_flag_1 = '';
                                ItemService.checkinItem(vm.item)
                                    .then( function() {
                                        vm.loadEvents();
                                        vm.updateSpecialActionButton();
                                    })
                                    .catch(function(error) {
                                        console.log(error);
                                    });
                            }
                            if (eventType==2) {
                                vm.item.item_flag_2= '';

                                ItemService.itemFromShop(vm.item)
                                    .then(function() {
                                        vm.loadEvents();
                                        vm.updateSpecialActionButton();
                                    })
                                    .catch(function(error) {
                                        console.log(error);
                                    });
                            }
                        })
                        .catch( function(error) {
                            console.log(error);
                        });
                },
                function() {
                    //error
                }
            );


            function getAttrs() {
                var attrs = {};
                switch(eventType) {
                    case 1:
                        attrs.label='Check In';
                        attrs.colorClass='success';
                        break;
                    case 2:
                        attrs.label='Return From Shop';
                        attrs.colorClass='primary';
                        break;
                    case 5:
                        attrs.label='Return Loan';
                        attrs.colorClass='success';
                        break;
                    case 7:
                        attrs.label='Check In';
                        attrs.colorClass='success';
                }
                return attrs;
            }

        }



        function showCreateEventModal(eventType, isLoan) {
            var newEvent = {};
            newEvent.event_type = eventType;
            newEvent.attrs = getAttrs();
            newEvent.shops = $scope.InventoryCtrl.account.shops;

            
            if (eventType == 2) {  //repair
                newEvent.selectedShop = $scope.InventoryCtrl.account.shops[0];
            }
           

            var modalInstance = $uibModal.open({
                templateUrl: 'app/views/partials/event-modal-create.html',
                controller: 'ModalInstanceCtrl',
                size: 'small',
                resolve: {
                    modalObject: function() {
                        return newEvent;
                    }
                }
            });

            modalInstance.result.then(
                function(modalResult) {
                    modalResult.event_date_1 = moment().format('YYYY-MM-DD');
                    modalResult.event_active = 1;
                    
                    modalResult.event_f_item_id = vm.item.item_id;


                    if( eventType==1 && isLoan===true ) {
                        modalResult.event_loaner_flag = '1';
                        vm.item.loaner_flag = 1;
                    }
                    if (eventType==2) {
                        modalResult.event_target = modalResult.selectedShop.shop_name;
                    }
                    DataService.submitObj('Event', modalResult)
                        .then(function() {
                            
                            if(eventType==1 || eventType==7) {
                                vm.item.item_field_5 = modalResult.event_target;
                                vm.item.item_flag_1 = 1;
                                
                                ItemService.checkoutItem(vm.item)
                                    .then(function() {
                                        vm.loadEvents();
                                        vm.updateSpecialActionButton();
                                    })
                                    .catch(function(error) {
                                        console.log(error);
                                    });
                            } else {
                                if(eventType==5) {
                                    vm.item.item_flag_1 = 1;
                                    ItemService.checkoutItem(vm.item)
                                        .then(function() {
                                            vm.loadEvents();
                                            vm.updateSpecialActionButton();
                                        })
                                        .catch(function(error) {
                                            console.log(error);
                                        });
                                } else {
                                    if(eventType==2) {
                                        vm.item.item_flag_2 = 1;
                                        
                                        ItemService.itemToShop(vm.item)
                                            .then(function() {
                                                vm.loadEvents();
                                                vm.updateSpecialActionButton();
                                            })
                                            .catch(function(error) {
                                                console.log(error);
                                            });
                                    } else {
                                        vm.loadEvents();
                                    }
                                }
                            }
                        })
                        .catch(function(error) {
                            console.log(error);
                        });
                }
            );

            function getAttrs() {
                var attrs = {};
                switch(eventType) {
                    case 1:
                        if (isLoan) {
                            attrs.label='Loan';
                        } else {
                            attrs.label='Check Out';
                        }
                        attrs.colorClass='success';
                        break;
                    case 2:
                        attrs.label='Send to Shop';
                        attrs.colorClass='primary';
                        break;
                    case 3:
                    case 6:
                    case 9:
                    case 10:
                        attrs.label='Add Note';
                        attrs.colorClass='default';
                        break;
                    case 4:
                        attrs.label='Add Performance';
                        attrs.colorClass='warning';
                        break;
                    case 5:
                        attrs.label='Loan';
                        attrs.colorClass='success';
                        break;
                    case 7:
                        attrs.label='Check Out';
                        attrs.colorClass='success';
                        break;
                    case 8:
                        attrs.label='Add Cleaning';
                        attrs.colorClass='primary';
                        break;
                }
                return attrs;
            }
        }

        function togglePhysicalCheck(item) {
            if (item.item_physical == 0 || item.item_physical == '' || item.item_physical == false) {
                item.item_physical = 1;
                DataService.submitObj('Item', item).then(function() {
                    // console.log('changed to true');
                })
                .catch(function(error) {
                    console.log(error);
                });          
            } else {
                item.item_physical = 0;
                DataService.submitObj('Item', item).then(function() {
                    // console.log('changed to false');
                    item.item_physical = 0;
                })
                .catch(function(error) {
                    console.log(error);
                });
            }
            
        }


        function showUpdateItemModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/views/partials/update-item-modal.html',
                controller: 'ModalInstanceCtrl',
                size: 'small',
                resolve: {
                    modalObject: function() {
                        return $scope;
                    }
                }
            });

            modalInstance.result.then( function(modalResult) {
                if (modalResult.ItemCtrl.item.selectedLocation) {
                    modalResult.ItemCtrl.item.item_f_location_id = modalResult.ItemCtrl.item.selectedLocation.location_id;
                }
                modalResult.ItemCtrl.item.item_physical = modalResult.ItemCtrl.item.item_physical ? 1 : 0;
                
                DataService.submitObj('Item', modalResult.ItemCtrl.item).then(function() {
                    if (modalResult.ItemCtrl.item.selectedLocation) {
                        modalResult.ItemCtrl.item.item_field_6 = modalResult.ItemCtrl.item.selectedLocation.location_name;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
            })
            .catch(function(error) {
                console.log(error);
            });
        }




        function confirmDelete() {
            if (confirm('Are you sure?')) {
                DataService.action('Item', vm.item.item_id, 'delete_item').then(function() {     
                    $scope.$emit('itemTypeChanged');               
                })
                .catch(function(error) {
                    console.log(error);
                });
            }
        }

        function loadEvents() {
            vm.loading = true;
            DataService.get('Item', vm.item.item_id, 'get_events').then(function(result) {
                vm.events = result;
                getCurrentEvents();
                vm.loading = false;
            })
            .catch(function(error) {
                console.log(error);
            });
        }

        function performAction() {
            vm.specialButtonAction();
        }



        function updateSpecialActionButton() {
            if ($scope.InventoryCtrl.activeClassification.classification_id == 1) {
                if (vm.item.item_flag_2 == '1') {
                    vm.specialButtonLabel = 'Return from shop';
                    vm.specialButtonAction= function() {
                        showUpdateEventModal(2);
                    };
                } else {
                    if (vm.item.item_flag_1 == '1') {
                        vm.specialButtonLabel = 'Check in';
                        vm.specialButtonAction= function() {
                            showUpdateEventModal(1);
                        };
                    } else {
                        vm.specialButtonLabel = 'Check out';
                        vm.specialButtonAction= function() {
                            showCreateEventModal(1);
                        };
                    }
                }
            } else {
                if ($scope.InventoryCtrl.activeClassification.classification_id == 2) {
                    if (vm.item.item_flag_1 == '1') {
                        vm.specialButtonLabel = 'Return loan';
                        vm.specialButtonAction = function() {
                            showUpdateEventModal(5);
                        };
                    } else {
                        vm.specialButtonLabel = 'Add performance';
                        vm.specialButtonAction= function() {
                            showCreateEventModal(4);
                        };
                    }
                    
                } else {
                    if ($scope.InventoryCtrl.activeClassification.classification_id == 3 ) {
                        if (vm.item.item_flag_1 == '1') {
                            vm.specialButtonLabel = 'Check in';
                            vm.specialButtonAction= function() {
                                showUpdateEventModal(7);
                            };
                        } else {
                            vm.specialButtonLabel = 'Check out';
                            vm.specialButtonAction= function() {
                                showCreateEventModal(7);
                            };
                        }
                    } else {
                        vm.specialButtonLabel = 'Add note';
                        vm.specialButtonAction= function() {
                            showCreateEventModal(1);
                        };
                    }
                }
            }
        } // updateSpecialActionButton

        



        // ******************************************* PRIVATE FUNCTIONS  *******************************************

        function getCurrentEvents() { // determine which user and repair records are active right now.
            var currentUserEvents = $.grep( vm.events, function( n ) {
                return ((n.event_type == 1 || n.event_type == 5 || n.event_type == 7 ) && n.event_active == 1);
            });
            vm.currentUserEvent = currentUserEvents.length > 0 ? currentUserEvents[0] : null;

            var currentRepairEvents = $.grep( vm.events, function( n ) {
                return (n.event_type == 2 && n.event_active == 1);
            });
            vm.currentRepairEvent = currentRepairEvents.length > 0 ? currentRepairEvents[0] : null;

            vm.item.selectedLocation = $.grep($scope.AccessCtrl.account.locations, function(n) {
                return n.location_id === vm.item.item_f_location_id;
            })[0];
        }


    }


})();
