(function() {
    'use strict';

    angular.module('itemType')
        .filter('locationFilter', locationFilter);

        function locationFilter() {
            return function(items, location) {

                if (!location) {
                    // console.log('no location set');
                    return items;
                } else {
                    // console.log()
                    var out = [];
                    items.forEach(function(item) {
                        if (item.item_f_location_id == location.location_id) {
                            out.push(item);
                        }
                    });
                    return out;
                }

            };
        }

})();
