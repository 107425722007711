(function() {
    'use strict';

    angular.module('itemType')
        .filter('classificationFilter', classificationFilter);

        function classificationFilter() {
            return function(items, classificationID) {

                if (!classificationID) {
                    // console.log('no location set');
                    return items;
                } else {
                    // console.log()
                    var out = [];
                    items.forEach(function(item) {
                        if (item.item_f_classification_id == classificationID) {
                            out.push(item);
                        }
                    });
                    return out;
                }

            };
        }

})();
