(function() {
    'use strict';

    angular.module('item')
        .filter('eventFilter', eventFilter);
        
        function eventFilter() {
            return function(events, filter) {
                
                if (!filter) {
                    
                    return events;
                } else {
                    
                    var out = [];
                    events.forEach(function(event) {
                        if (event.event_type == filter) {
                            out.push(event);
                        }
                    });
                    return out;
                }
                
            }
        }

})();