/**=========================================================
 * Module: config.js
 * App routes and resources configuration
 =========================================================*/


(function() {
    'use strict';

    angular
        .module('app.routes')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', '$locationProvider', '$urlRouterProvider', 'RouteHelpersProvider'];
    function routesConfig($stateProvider, $locationProvider, $urlRouterProvider, helper){

        // Set the following to true to enable the HTML5 Mode
        // You may have to set <base> tag in index and a routing configuration in your server
        $locationProvider.html5Mode(false);

        // defaults to dashboard
        $urlRouterProvider.otherwise('app/access/login');


        $stateProvider
            .state('app', {
                url: '/app',
                abstract: true,
                template: '<div ui-view class="wrapper" style="height:100%"/>',
                resolve: helper.resolveFor('modernizr', 'whirl', 'ui.scroll'),
                controller: 'AccessController as AccessCtrl'
            })
                .state('app.access', {
                url: '/access',
                abstract: true,
                templateUrl: helper.basepath('access.html'),
                resolve: helper.resolveFor('modernizr', 'icons', 'moment')
                })
                    .state('app.access.login', {
                        url: '/login',
                        title: 'Login',
                        templateUrl: helper.basepath('login.html')
                    })
                    .state('app.access.change', {
                        url: '/changePassword',
                        title: 'Change Password',
                        templateUrl: helper.basepath('changepassword.html')
                    })
                .state('app.inventory', {
                    url: '/inventory',
                    abstract: true,
                    templateUrl: helper.basepath('inventory.html'),
                    resolve: helper.resolveFor('naturalSort','modernizr', 'icons', 'localytics.directives',  'whirl', 'ui.checkbox', 'moment', 'ngInfiniteScroll'),
                    controller: 'InventoryController as InventoryCtrl'
                })
                    .state('app.inventory.classification', {
                        url: '/classification',
                        title: 'Classification',
                        templateUrl: helper.basepath('cover-page.html')
                    })
                    .state('app.inventory.category', {
                        url: '/category',
                        title: 'Category',
                        templateUrl: helper.basepath('cover-page.html')
                    })
                    .state('app.inventory.itemtype', {
                        url: '/itemtype',
                        title: 'Listing',
                        templateUrl: helper.basepath('listing-page.html'),
                        controller: 'ItemTypeController as ItemTypeCtrl',
                        resolve: helper.resolveFor('moment')
                    })
                    .state('app.inventory.repair', {
                        url: '/repair',
                        title: 'Listing',
                        templateUrl: helper.basepath('listing-page.html'),
                        controller: 'ItemTypeController as ItemTypeCtrl',
                        resolve: helper.resolveFor('moment')
                    })
                    .state('app.inventory.searchResults', {
                        url: '/searchResults',
                        title: 'Listing',
                        templateUrl: helper.basepath('search-results.html'),
                        controller: 'ItemTypeController as ItemTypeCtrl',
                        resolve: helper.resolveFor('moment')
                    })


            ;

    }

})();
