

// APP START
// ----------------------------------- 

(function() {
    'use strict';

    angular
        .module('pps', [
            'app.core',
            'app.routes',
            'app.sidebar',
            'app.navsearch',
            'app.preloader',
            'app.loadingbar',
            'app.translate',
            'app.settings',
            'app.utils',
            'access',
            
            'common',
            'eventHistory', 
            'item',
            'itemType',
            'inventory',
            'print'
        ]);
})();

