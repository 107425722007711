(function() {
    'use strict';

    angular.module('item')
        .factory('ItemService', ItemService);
        
    ItemService.$inject=['$q', '$http', 'DataService'];
    
    function ItemService($q, $http, DataService) {
        return  {
            checkoutItem: checkoutItem,
            checkinItem: checkinItem,
            deleteItem: deleteItem,
            itemToShop: itemToShop,
            itemFromShop: itemFromShop,
            saveItem: saveItem,
            markPhysical: markPhysical   
        };

        function checkoutItem(item) {
            item.item_flag_1 = 1;
            return perform(item);
        }

        function checkinItem(item) {
            item.item_flag_1 = 0;
            return perform(item);
        }

        function itemFromShop(item) {
            item.item_flag_2 = 0;
            return perform(item);
        }

        function itemToShop(item) {
            item.item_flag_2 = 1;
            return perform(item);
        }

        function saveItem(newItem) {
            return DataService.submitObj('Item', newItem);
        }

        function deleteItem(item) {
            return DataService.action('Item', item.item_id, 'delete_item'); 
        }

        function markPhysical(item, val) {
            item.item_physical = val;
            return DataService.submitObj('Item', item);
        }


// ******************************************* PRIVATE FUNCTIONS  *******************************************

        function perform(item) {
            return DataService.submitObj('Item', item);
        }
        
    }
})();
